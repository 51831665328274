import { useEffect } from 'react';

export function CursorTrail() {
  useEffect(() => {
    const createTrailElement = (x, y) => {
      const trail = document.createElement('div');
      trail.className = 'cursor-trail';
      trail.style.left = `${x}px`;
      trail.style.top = `${y}px`;
      document.body.appendChild(trail);

      setTimeout(() => {
        document.body.removeChild(trail);
      }, 500);
    };

    const createExplosion = (x, y) => {
      const particleCount = 12; // Number of particles in explosion
      for (let i = 0; i < particleCount; i++) {
        const particle = document.createElement('div');
        particle.className = 'cursor-explosion';
        particle.style.left = `${x}px`;
        particle.style.top = `${y}px`;
        // Random angle for each particle
        const angle = (i / particleCount) * 2 * Math.PI;
        particle.style.setProperty('--angle', `${angle}rad`);
        document.body.appendChild(particle);

        setTimeout(() => {
          document.body.removeChild(particle);
        }, 1000);
      }
    };

    let throttleTimer;
    const throttle = (callback, time) => {
      if (throttleTimer) return;
      throttleTimer = setTimeout(() => {
        callback();
        throttleTimer = null;
      }, time);
    };

    const handleMouseMove = (e) => {
      throttle(() => {
        createTrailElement(e.clientX, e.clientY);
      }, 50);
    };

    const handleClick = (e) => {
      createExplosion(e.clientX, e.clientY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('click', handleClick);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return null;
} 