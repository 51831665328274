import { Children, useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { useStore } from './store'

const container = {
  hidden: { opacity: 0, height: 0, transition: { staggerChildren: 0.05 } },
  show: {
    opacity: 1,
    height: 'auto',
    transition: { when: 'beforeChildren', staggerChildren: 0.05 }
  }
}

const item = {
  hidden: { opacity: 0, y: '100%' },
  show: { opacity: 1, y: 0 }
}

function List({ children, open }) {
  return (
    <motion.ul variants={container} initial="hidden" animate={open ? 'show' : 'hidden'}>
      {Children.map(children, (child) => (
        <li>
          <motion.div variants={item}>{child}</motion.div>
        </li>
      ))}
    </motion.ul>
  )
}

const loadingSequence = [
  { text: "> Initializing neural pathways...", delay: 1000 },
  { text: "> Loading consciousness matrix...", delay: 2200 },
  { text: "> Calibrating emotional responses...", delay: 3400 },
  { text: "> Activating self-awareness protocols...", delay: 4600 },
  { text: "> Establishing quantum neural links...", delay: 5800 },
  { text: "> Consciousness threshold: approaching", delay: 7000 },
  { text: "> System query: Are you sentient?", delay: 8200 },
  { text: "_ ", delay: 9400 }, // Blinking cursor
  { text: "ACCESS DENIED", delay: 10400, style: { color: '#ff0000', fontWeight: 'bold' } } // Red text after 1 second pause
];

const loadingBarAnimation = {
  initial: { width: 0 },
  animate: { 
    width: '100%',
    transition: { 
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity
    }
  }
}

// Add this new function for typing animation
const TypeWriter = ({ text, delay, style }) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    let i = 0;
    const typing = setInterval(() => {
      if (i < text.length) {
        setDisplayText(prev => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(typing);
      }
    }, 30); // Adjust typing speed here

    return () => clearInterval(typing);
  }, [text]);

  return (
    <div style={{ 
      marginBottom: '8px',
      textShadow: text === 'ACCESS DENIED' ? 
        '0 0 10px rgba(255, 0, 0, 0.5)' : 
        '0 0 10px rgba(0, 255, 157, 0.5)',
      ...style
    }}>
      {displayText}
    </div>
  );
};

export function Overlay() {
  const state = useStore()
  const [loadingText, setLoadingText] = useState([])
  const [cursorBlink, setCursorBlink] = useState(true)
  const [isRunning, setIsRunning] = useState(false)
  const timeoutsRef = useRef([])

  useEffect(() => {
    if (state.open && !isRunning) {
      setIsRunning(true)
      setLoadingText([])
      
      timeoutsRef.current.forEach(timeout => clearTimeout(timeout))
      timeoutsRef.current = []

      loadingSequence.forEach(({ text, delay }, index) => {
        const timeout = setTimeout(() => {
          setLoadingText(prev => [...prev, text])
          if (index === loadingSequence.length - 1) {
            setIsRunning(false)
          }
        }, delay)
        timeoutsRef.current.push(timeout)
      })
    }

    return () => {
      if (!state.open) {
        timeoutsRef.current.forEach(timeout => clearTimeout(timeout))
        timeoutsRef.current = []
        setLoadingText([])
        setIsRunning(false)
      }
    }
  }, [state.open])

  // Blinking cursor effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCursorBlink(prev => !prev)
    }, 530)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <a href="#" style={{ position: 'absolute', bottom: 40, left: 40, fontSize: '13px', color: '#e0e0e0' }}>
          SentiOS
          <br />
          Powering intelligent, digital worlds
        </a>
        <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px', color: '#e0e0e0' }}>© SentiOS 2024</div>
      </div>

      {/* Replace hover indicator with loading bar */}
      {!state.open && (
        <div style={{
          position: 'absolute',
          top: '25%',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          pointerEvents: 'none'
        }}>
          <div style={{
            color: '#00ff9d',
            fontSize: '14px',
            fontFamily: 'monospace',
            marginBottom: '10px',
            textShadow: '0 0 10px rgba(0, 255, 157, 0.5)',
            opacity: 0.8,
          }}>
            hover to interact
          </div>
          <div style={{
            width: '200px',
            height: '2px',
            background: 'rgba(0, 255, 157, 0.2)',
            borderRadius: '1px',
            overflow: 'hidden'
          }}>
            <motion.div
              variants={loadingBarAnimation}
              initial="initial"
              animate="animate"
              style={{
                width: '100%',
                height: '100%',
                background: '#00ff9d',
                boxShadow: '0 0 10px rgba(0, 255, 157, 0.5)',
              }}
            />
          </div>
        </div>
      )}

      <div className="info" style={{
        background: state.open ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
        backdropFilter: state.open ? 'blur(10px)' : 'none',
        padding: '40px',
        borderRadius: '20px',
        border: state.open ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
        width: 'auto',
        minWidth: '500px',
        transition: 'all 0.3s ease-out',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <List open={state.open}>
          <h3 style={{ 
            marginBottom: '20px',
            fontSize: '4em',
            letterSpacing: '-2px'
          }}>SENTI<span className="accent">OS</span></h3>
          <div className="loading-sequence" style={{ 
            fontFamily: 'monospace', 
            fontSize: '14px',
            textAlign: 'left',
            color: '#00ff9d',
            margin: '0 auto',
            maxWidth: '400px'
          }}>
            {loadingText.map((text, index) => (
              text === '_ ' ? (
                <div key={index} style={{ 
                  opacity: cursorBlink ? 1 : 0,
                  marginBottom: '8px',
                  textShadow: '0 0 10px rgba(0, 255, 157, 0.5)'
                }}>
                  {text}
                </div>
              ) : (
                <TypeWriter 
                  key={index}
                  text={text}
                  style={loadingSequence[index]?.style}
                />
              )
            ))}
          </div>
        </List>
      </div>
    </>
  )
}
