import { useRef, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, MeshTransmissionMaterial, ContactShadows, Environment, MeshDistortMaterial } from '@react-three/drei'
import { easing } from 'maath'
import { useStore } from './store'
import * as THREE from 'three'

function EnergyParticles({ count = 100 }) {
  const points = useRef()
  
  const particleData = useMemo(() => {
    const positions = new Float32Array(count * 3)
    const velocities = new Array(count).fill().map(() => ({
      x: (Math.random() - 0.5) * 0.014,
      y: (Math.random() - 0.5) * 0.014,
      z: (Math.random() - 0.5) * 0.014,
      phaseOffset: Math.random() * Math.PI * 2
    }))
    
    for (let i = 0; i < count; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10
    }
    
    return { positions, velocities }
  }, [count])

  useFrame((state) => {
    const positions = points.current.geometry.attributes.position.array
    const time = state.clock.getElapsedTime()
    
    for (let i = 0; i < count; i++) {
      positions[i * 3] += particleData.velocities[i].x
      positions[i * 3 + 1] += particleData.velocities[i].y
      positions[i * 3 + 2] += particleData.velocities[i].z
      
      const pulse = Math.sin(time * 2 + particleData.velocities[i].phaseOffset) * 0.5 + 0.5
      points.current.material.opacity = 0.1 + pulse * 0.4
      
      if (Math.abs(positions[i * 3]) > 5) positions[i * 3] *= -0.9
      if (Math.abs(positions[i * 3 + 1]) > 5) positions[i * 3 + 1] *= -0.9
      if (Math.abs(positions[i * 3 + 2]) > 5) positions[i * 3 + 2] *= -0.9
    }
    
    points.current.geometry.attributes.position.needsUpdate = true
  })

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={count}
          array={particleData.positions}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.03}
        color="#00ff9d"
        transparent
        opacity={0.6}
        blending={THREE.AdditiveBlending}
      />
    </points>
  )
}

// Add this new component after the imports
function WaveGrid() {
  const points = useRef()
  const gridSize = 20
  const spacing = 0.2
  const darkGreen = '#00804d'
  
  const gridData = useMemo(() => {
    const positions = new Float32Array(gridSize * gridSize * 3)
    const connections = []
    
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const idx = (i * gridSize + j) * 3
        positions[idx] = (j - gridSize/2) * spacing * 1.5
        positions[idx + 1] = -0.8
        positions[idx + 2] = (i - gridSize/2) * spacing * 1.5 - 1.5
        
        if (j < gridSize - 1) connections.push(idx, idx + 3)
        if (i < gridSize - 1) connections.push(idx, idx + gridSize * 3)
      }
    }
    
    return { positions, connections: new Uint16Array(connections) }
  }, [])

  useFrame((state) => {
    const positions = points.current.geometry.attributes.position.array
    const time = state.clock.getElapsedTime()
    
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const idx = (i * gridSize + j) * 3
        positions[idx + 1] = -0.8 + 
          Math.sin(time + i * 0.2) * 0.02 + 
          Math.cos(time + j * 0.2) * 0.02
      }
    }
    
    points.current.geometry.attributes.position.needsUpdate = true
  })

  return (
    <group>
      <points ref={points}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={gridSize * gridSize}
            array={gridData.positions}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          size={0.02}
          color={darkGreen}
          transparent
          opacity={0.3}
          blending={THREE.NormalBlending}
        />
      </points>
      
      <lineSegments>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={gridSize * gridSize}
            array={gridData.positions}
            itemSize={3}
          />
          <bufferAttribute
            attach="index"
            array={gridData.connections}
            count={gridData.connections.length}
          />
        </bufferGeometry>
        <lineBasicMaterial
          color={darkGreen}
          transparent
          opacity={0.05}
          blending={THREE.AdditiveBlending}
        />
      </lineSegments>
    </group>
  )
}

// Then add it to the Canvas, just before the EnergyParticles
export default function App() {
  const store = useStore()
  return (
    <Canvas eventSource={document.getElementById('root')} eventPrefix="client" camera={{ position: [0, 0, 4], fov: 40 }}>
      <color attach="background" args={['#1a1a1a']} />
      <ambientLight intensity={0.7} />
      <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, -5]} castShadow />
      <Environment preset="night" background={false} blur={store.open ? 0 : 1} />
      <WaveGrid />
      <EnergyParticles />
      <Selector>
        <Glow>
          <Shoe rotation={[0.3, Math.PI / 1.6, 0]} />
        </Glow>
      </Selector>
    </Canvas>
  )
}

function Selector({ children }) {
  const ref = useRef()
  const store = useStore()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store.open ? 4 : 2, store.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store.open ? '#ffffff' : '#e0e0e0', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref}>
        <circleGeometry args={[1, 64, 64]} />
        <MeshTransmissionMaterial 
          samples={16} 
          resolution={1024} 
          anisotropicBlur={0.5} 
          thickness={0.2} 
          roughness={0.25} 
          opacity={0.6} 
          transparent={true} 
          toneMapped={true} 
          backdropOpacity={0.6} 
          background={new THREE.Color('#1a1a1a')}
        />
      </mesh>
      <group
        onPointerOver={() => (store.open = true)}
        onPointerOut={() => (store.open = false)}
        onPointerDown={() => (store.open = true)}
        onPointerUp={() => (store.open = false)}>
        {children}
      </group>
    </>
  )
}

function Glow({ children }) {
  const store = useStore()
  return (
    <group>
      <mesh scale={[0.4, 0.4, 0.4]} position={[0, 0, -0.7]}>
        <sphereGeometry args={[1.2, 32, 32]} />
        <MeshDistortMaterial
          color="#00ff9d"
          transparent
          opacity={store.open ? 0.8 : 0.3}
          distort={0.3}
          speed={1}
          roughness={0.1}
          metalness={0.8}
          transmission={0.6}
          emissive="#00ff9d"
          emissiveIntensity={store.open ? 2 : 1}
        />
      </mesh>
      {children}
    </group>
  )
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 mesh-mask.glb --transform
Author: quaz30 (https://sketchfab.com/quaz30)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/nike-air-zoom-pegasus-36-00fd99e778c244c3bd3b65f99dad7cb2
Title: Nike Air Zoom Pegasus 36
*/

function Shoe(props) {
  const ref = useRef()
  const { nodes, materials } = useGLTF('/mesh-mask-transformed.glb')

  if (!nodes || !materials) {
    return null
  }

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.set(
      Math.cos(t / 4) / 24,
      Math.sin(t / 3) / 12,
      0.15 + Math.sin(t / 2) / 24
    )
    ref.current.position.y = (0.5 + Math.cos(t / 2)) / 20
  })

  return (
    <group ref={ref}>
      <mesh receiveShadow castShadow geometry={nodes.node0.geometry} material={materials.material0} {...props} />
    </group>
  )
}

useGLTF.preload('/mesh-mask-transformed.glb')
